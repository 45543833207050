exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-access-tsx": () => import("./../../../src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-blood-donors-tsx": () => import("./../../../src/pages/blood-donors.tsx" /* webpackChunkName: "component---src-pages-blood-donors-tsx" */),
  "component---src-pages-case-report-tsx": () => import("./../../../src/pages/case-report.tsx" /* webpackChunkName: "component---src-pages-case-report-tsx" */),
  "component---src-pages-chemotherapy-tsx": () => import("./../../../src/pages/chemotherapy.tsx" /* webpackChunkName: "component---src-pages-chemotherapy-tsx" */),
  "component---src-pages-clinical-examination-tsx": () => import("./../../../src/pages/clinical-examination.tsx" /* webpackChunkName: "component---src-pages-clinical-examination-tsx" */),
  "component---src-pages-doctor-tsx": () => import("./../../../src/pages/doctor.tsx" /* webpackChunkName: "component---src-pages-doctor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-liver-tumor-tsx": () => import("./../../../src/pages/liver-tumor.tsx" /* webpackChunkName: "component---src-pages-liver-tumor-tsx" */),
  "component---src-pages-oncology-surgery-tsx": () => import("./../../../src/pages/oncology-surgery.tsx" /* webpackChunkName: "component---src-pages-oncology-surgery-tsx" */),
  "component---src-pages-owner-tsx": () => import("./../../../src/pages/owner.tsx" /* webpackChunkName: "component---src-pages-owner-tsx" */),
  "component---src-pages-palliative-care-tsx": () => import("./../../../src/pages/palliative-care.tsx" /* webpackChunkName: "component---src-pages-palliative-care-tsx" */),
  "component---src-pages-radiation-therapy-tsx": () => import("./../../../src/pages/radiation-therapy.tsx" /* webpackChunkName: "component---src-pages-radiation-therapy-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-pages-research-achievement-tsx": () => import("./../../../src/pages/research-achievement.tsx" /* webpackChunkName: "component---src-pages-research-achievement-tsx" */),
  "component---src-templates-report-tsx": () => import("./../../../src/templates/report.tsx" /* webpackChunkName: "component---src-templates-report-tsx" */)
}

